<template>
  <HourglassLoader :loading="loading">
    <div class="card card-body bg-white border-light shadow-sm mb-4">
      <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-4"
      >
        <h2 class="h5">Profile information</h2>

        <button
          class="btn btn-primary btn-sm mr-2"
          :class="isEditing ? 'btn-danger' : 'btn-primary'"
          @click="isEditing = !isEditing"
          disabled
        >
          <span
            class="fas mr-1"
            :class="isEditing ? 'fa-times' : 'fa-edit'"
          ></span>
          {{ isEditing ? "Cancel" : "Edit" }}
        </button>
      </div>
      <form @submit.prevent="SaveProfileInformation">
        <div class="row">
          <div class="col-md-6 mb-3">
            <div>
              <label for="first_name">First Name</label>
              <InputWrapper :errors="formError.first_name">
                <input
                  class="form-control"
                  id="first_name"
                  type="text"
                  v-model="formData.first_name"
                  :disabled="!isEditing"
                  placeholder="Enter your first name"
                  required
                />
              </InputWrapper>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div>
              <label for="last_name">Last Name</label>
              <InputWrapper :errors="formError.last_name">
                <input
                  class="form-control"
                  id="last_name"
                  type="text"
                  :disabled="!isEditing"
                  v-model="formData.last_name"
                  placeholder="Also your last name"
                  required
                />
              </InputWrapper>
            </div>
          </div>
        </div>
        <div v-if="isEditing" class="mt-3 text-center">
          <button type="submit" class="btn btn-primary">Save Profile</button>
        </div>
      </form>
    </div>
  </HourglassLoader>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import InputWrapper from "@/components/InputWrapper";
import HourglassLoader from "@/components/loader/HourglassLoader";

export default {
  name: "ProfileInformation",
  components: { HourglassLoader, InputWrapper },
  setup() {
    const store = useStore();
    const formData = ref({
      first_name: store.getters.profile?.first_name,
      last_name: store.getters.profile?.last_name,
    });

    const formError = ref({});
    const isEditing = ref(false);
    const loading = ref(false);

    return {
      store,
      formData,
      formError,
      isEditing,
      loading,
    };
  },
};
</script>

<style scoped></style>
