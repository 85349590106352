<template>
  <div class="card card-body bg-white border-light shadow-sm mb-4">
    <h2 class="h5 my-4">Account information</h2>
    <form>
      <div class="row">
        <div class="col-md-6 mb-3">
          <div class="form-group">
            <label for="username">Email</label>
            <input
              class="form-control"
              disabled
              v-model="store.getters.email"
              id="username"
              type="text"
              placeholder="uniqueUser"
              required
            />
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="form-group">
            <label for="phone">Phone</label>
            <input
              class="form-control"
              disabled
              :value="store.getters.profile?.phone"
              id="phone"
              type="number"
              placeholder="08090901101"
              required
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "AccountInformation",
  setup() {
    const store = useStore();

    return {
      store
    };
  }
};
</script>

<style scoped></style>
