<template>
  <section>
    <div class="row mt-4">
      <div class="col-12 col-xl-8">
        <ProfileInformation v-if="store.state.user.id" />
        <div v-else class="display-1 text-danger text-center my-3">
          <div class="card card-body bg-white border-light shadow-sm">
            Profile not found
          </div>
        </div>

        <AccountInformation />
      </div>
      <div class="col-12 col-xl-4">
        <UserInfoCard />
      </div>
    </div>
  </section>
</template>

<script>
import { useStore } from "vuex";
import UserInfoCard from "@/components/UserInfoCard";
import ProfileInformation from "@/components/profile/ProfileInformation";
import AccountInformation from "@/components/profile/AccountInformation";

export default {
  name: "ProfileSettings",
  components: {
    AccountInformation,
    ProfileInformation,
    UserInfoCard,
  },
  setup() {
    const store = useStore();

    return {
      store,
    };
  },
};
</script>

<style scoped></style>
