
import { useStore } from "vuex";
import { defineComponent } from "vue";
import DefaultLoader from "@/components/loader/DefaultLoader.vue";

export default defineComponent({
  name: "UserInfoCard",
  components: { DefaultLoader },
  setup() {
    const store = useStore();

    return {
      store,
    };
  },
});
