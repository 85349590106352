<template>
  <DefaultLoader>
    <div class="row">
      <div class="col-12">
        <div class="card border-light text-center p-0">
          <div
            class="profile-cover rounded-top"
            data-bs-background="@/assets/logo_mini.png"
          ></div>
          <div class="card-body pb-5">
            <img
              src="@/assets/logo_mini.png"
              class="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4"
              alt="Neil Portrait"
            />
            <h4 class="h3">
              {{ store.getters.profile?.first_name }}
              {{ store.getters.profile?.last_name }}
            </h4>
            <h5 class="font-weight-normal">@{{ store.state.user.username }}</h5>
            <p class="text-gray mb-4">{{ store.getters.profile?.phone }}</p>
          </div>
        </div>
      </div>
    </div>
  </DefaultLoader>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { defineComponent } from "vue";
import DefaultLoader from "@/components/loader/DefaultLoader.vue";

export default defineComponent({
  name: "UserInfoCard",
  components: { DefaultLoader },
  setup() {
    const store = useStore();

    return {
      store,
    };
  },
});
</script>

<style scoped></style>
